<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
        <li aria-current="page" class="active">Odpovedani prevozi</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Vpiši novo donacijo" @click.stop="addCanceledReservations()"></vs-button>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange()}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange()}"></vs-button>

        <v-select id="unit" :options="unitsData" class="ml-20" @input="onUnitChange" style="min-width: 300px"
                  v-model="unitId" placeholder="Filtriraj zadetke po enoti"
                  :reduce="item => item.id"
                  v-if="$globalFunctions.isAdmin() && unitsData"
                  :clearable="false"
                  label="name">

          <template v-slot:option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:selected-option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
          </template>
        </v-select>


        <download-excel :data="canceledReservationsData"
                        :fields="fields_to_export"
                        worksheet="Odpovedani prevozi"
                        :name="moment(selectedDate).year() + '_' + (moment(selectedDate).month()+1) + '_odpovedani_prevozi.xls'"
                        class="ml-auto"
                        v-if="canceledReservationsData && canceledReservationsData.length > 0">
          <vs-button color="success" icon="description">Izvozi v Excel</vs-button>
        </download-excel>

      </vs-col>
    </vs-row>

    <div v-if="canceledReservationsData && canceledReservationsData.length > 0" class="mt-10">
      <CanceledOrDeclinedReservations :reservations-data="canceledReservationsData" :type="'CANCELED'"/>
    </div>
    <div v-if="canceledReservationsData && canceledReservationsData.length === 0">
      <vs-alert
        class="mt-5"
        color="danger"
        :title="'Ni zapisov'"
        active="true">
        Za izbran mesec in/ali enoto ni zapisov.
      </vs-alert>

    </div>


    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">


      </vs-sidebar>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import CanceledOrDeclinedReservations from "../../components/Sopotniki/reservations/CanceledOrDeclinedReservations";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";


export default {
  name: 'companies',

  components: {
    'v-select': vSelect,
    Datepicker,
    CanceledOrDeclinedReservations
  },

  data() {
    return {
      selectedDate: new Date(),
      slSI: slSI,

      canceledReservationsData: null,
      unitsData: null,
      companyId: null,
      sidebarActive: false,
      unitId: -1,
      donationsTotal: 0,
      fields_to_export: {
        "Datum": {
          field: "date",
          callback: (value) => {
            return this.moment(value).subtract(3, 'hours').format('DD. MM. YYYY');
          },
        },
        "Potnik": {
          callback: (value) => {
            return value.customer ? value.customer.firstName + ' ' + value.customer.lastName : value.customer_name;
          },
        },
        "Razlog": "reason",
        "Tip": {
          field: "type",
          callback: (value) => {
            return value === 'CANCELED' ? 'odpovedan prevoz' : 'zavrnjen prevoz';
          },
        },
        "Enota": {
          field: "unit.name",
        },
        "Občina/-e": {
          field: "unit.coveredMunicipalities",
        },
      }
    }
  },

  computed: {},

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForCanceledReservations;

    _this.$vs.loading();

    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {
      _this.$vs.loading.close();
      _this.getCanceledReservations();
    });

  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.companyId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    onUnitChange() {
      const _this = this;

      _this.getCanceledReservations(_this.unitId);
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {

          _this.unitsData = _.filter(res.data.data, {disabled: 0});
          _this.unitsData.push({
            id: -1,
            name: 'Vse enote',
            coveredMunicipalities: 'podatki za vse enote'
          })
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    getCanceledReservations(unitId) {
      const _this = this;

      console.log("unitId", unitId);
      _this.$vs.loading();

      let currentUnit = null;
      if(_this.$globalFunctions.isAdmin()) {
        currentUnit = _this.unitId === -1 ? null : _this.unitId;
      } else {
        currentUnit = _this.$globalFunctions.getCurrentUnit().id;
      }

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations', {
        params: {
          unit: currentUnit,
          startDate: _this.moment(_this.selectedDate).startOf('month').valueOf(),
          endDate: _this.moment(_this.selectedDate).endOf('month').valueOf(),
          "type": "CANCELED",
        }
      })
        .then((res) => {

          // TODO: remove this after BE update
          if(_this.$globalFunctions.isAdmin()) {
            _this.canceledReservationsData = res.data.data;
          } else {
            _this.canceledReservationsData = _.filter(res.data.data, function(val){
              if(val.unit.id === _this.$globalFunctions.getCurrentUnit().id) {
                return val
              }
            });
          }

          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForCanceledReservations', this.selectedDate);
      this.getCanceledReservations();
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    addCanceledReservations() {
      const _this = this;
      _this.$router.push({name: 'CanceledReservationEdit'});
    },
  }
}
</script>

